import React from 'react'
import Cartitems from '../Components/CartItems/Cartitems'

const Cart = () => {
  return (
    <div className='Cart'>
      <Cartitems />
    </div>
  )
}

export default Cart