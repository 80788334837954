import React from 'react'
import './Popular.scss'
import Item from '../Item/Item'
import data_product from '../../Assets/data'

const Popular = () => {
  return (
    <>
    <div className='Popular'>
      <h1>POPULAR IN WOMENS</h1>
      <hr />
      <div className="popular-item">
        {data_product.map((item, i)=>{
          return <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} />
        })}
      </div>
    </div>
    </>
  )
}

export default Popular